





















import { Component, Vue } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { UserService } from '@/services/user-service';
import NovaTransfer from './NovaTransfer.vue';
import { DialogService } from '@/services/common/dialog.service';
import { CloseStatus } from '@/services/common/models/dialog.interface';

@Component({
  components: { NovaTransfer }
})
export default class AddManagerUser extends Vue {
  public rightTitle: string = '已添加成员';
  // private dialogVisible: boolean = false;
  private list: any[] = [];
  private data!: any;
  // i8n
  @Inject(UserService) private userService!: UserService;
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(DialogService) private dialog!: DialogService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public created(): void {
    this.list = this.data.list;
    if (this.data.rightTitle) {
      this.rightTitle = this.data.rightTitle;
    }
  }

  public submitForm(): void {
    this.dialog.close(CloseStatus.confirm, this.list, this.data.key);
  }

  public cancelForm(): void {
    this.dialog.close(CloseStatus.close, void 0, this.data.key);
  }
}
