



























































































































import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { UserService } from '@/services/user-service';
import {
  cloneDeep,
  debounce,
  findIndex,
  forEach,
  isArray,
  isString,
  lowerCase,
  some
} from 'lodash';
import { IAdminUser } from '@/views/manager';
const infiniteScroll = require('vue-infinite-scroll');

@Component({
  name: 'nova-transfer',
  directives: { infiniteScroll }
})
export default class NovaTransfer extends Vue {
  public leftSearchChangeProxy = debounce(this.leftSearchChange, 800);
  public leftSearch: string = '';
  public rightSearch: string = '';
  public rightisIndeterminate: boolean = true;
  @Prop({ default: '已添加人员' })
  public rightTitle!: string;
  public loading: boolean = false;
  public busy: boolean = false;
  // 初始化数组
  private leftdata: any = [];
  private rightdata: any = [];
  private leftCheckList: any = [];
  private rightCheckList: any = [];
  // 左侧可选
  private leftcheckAll: boolean = false;
  // private leftisIndeterminate: boolean = true;
  private rightcheckAll: boolean = false;
  private scrollQuery: Dict<any> = {
    offset: 1,
    limit: 10
  };
  private total: number = 0;

  @Model('change', { type: Array, default: () => [] })
  private rightInitData!: any[];

  @Inject(UserService)
  private userService!: UserService;
  // i18n
  @Inject(I18nService)
  private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public async loadMore(): Promise<void> {
    if (this.total >= this.scrollQuery.offset * this.scrollQuery.limit) {
      this.busy = true;
      this.scrollQuery.offset++;
      const res = await this.userService.getUserPropilePaging({
        offset: this.scrollQuery.offset,
        limit: this.scrollQuery.limit
      });
      if (res && isArray(res.records)) {
        this.leftdata.push(...res.records);
      }
      this.total = res.total;
      this.initData(res.records);
      this.busy = false;
    }
  }

  public moveToLeft(): void {
    this.rightcheckAll = this.rightcheckAll
      ? !this.rightcheckAll
      : this.rightcheckAll;
    let newRightData = cloneDeep(this.rightInitData);
    this.rightCheckList.forEach((item: any) => {
      const index = this.rightdata.findIndex(
        (rightItem: any) => rightItem.employeeId === item.employeeId
      );
      const totalIndex = newRightData.findIndex(
        (rightItem: any) => rightItem.employeeId === item.employeeId
      );
      newRightData.splice(totalIndex, 1);
      this.rightdata.splice(index, 1);
      this.leftdata.push(item);
    });
    this.$emit('change', newRightData);
    this.rightCheckList = [];
  }

  // 右侧逻辑
  public rightCheckAllChange(val: boolean): void {
    this.rightCheckList = val ? this.rightdata.slice(0) : [];
    this.rightisIndeterminate = false;
  }

  public moveToRight(): void {
    this.leftcheckAll = this.leftcheckAll
      ? !this.leftcheckAll
      : this.leftcheckAll;
    this.leftCheckList.forEach((item: any) => {
      const index = this.leftdata.findIndex(
        (leftItem: any) => leftItem.employeeId === item.employeeId
      );
      this.rightdata.push(item);
      this.leftdata.splice(index, 1);
    });
    this.$emit('change', this.rightdata);
    this.leftCheckList = [];
  }

  public rightSearchChange(val: string): void {
    if (!val) {
      this.rightdata = cloneDeep(this.rightInitData);
    } else {
      this.rightdata = this.rightInitData.filter((item: any) => {
        return some(
          [item.fullName, item.employeeId, item.email],
          (e) => e && lowerCase(e).indexOf(lowerCase(val)) > -1
        );
      });
    }
  }

  public async mounted(): Promise<void> {
    await this.getUserAll();
    // this.leftSearch = ' ';
    // this.$forceUpdate();
  }

  private leftSearchChange(event: any): void {
    const keyword = isString(event)
      ? event
      : (event as any).currentTarget.querySelector('input').value;
    this.getUserAll(keyword);
  }

  private async getUserAll(searchParam: string = ''): Promise<void> {
    this.scrollQuery.searchParam = searchParam;
    this.scrollQuery.offset = 1;
    this.loading = true;
    const res = await this.userService.getUserPropilePaging(this.scrollQuery);
    this.loading = false;
    if (!res) {
      return;
    }
    this.leftdata = res.records;
    this.total = res.total;
    this.initData(this.leftdata);
  }

  private initData(users: IAdminUser[]): void {
    this.rightdata = cloneDeep(this.rightInitData);
    forEach(this.rightInitData, (outitem: any) => {
      const index = findIndex(users, (item: any) => item.id === outitem.id);
      if (index > -1) {
        users.splice(index, 1);
      }
    });
  }
}
