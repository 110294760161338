import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class ChannelService {
  @Inject(HttpService) private http!: HttpService;
  public getChannelList(): Promise<any> {
    return this.http.get('/api/channels');
  }

  public getChannels(param: Dict<any>): Promise<any> {
    return this.http.get('/api/admin/applications/page/' + param.offset + '/' + param.limit);
  }


  public changeActive(param: Dict<any>) {
    let url: string = '';
    if (param.type === 'active') {
      url = '/api/channels/deactivate/' + param.channelId;
    } else {
      url = '/api/channels/active/' + param.channelId;
    }
    return this.http.post(url);
  }

  public doAnync(param: Dict<any>): Promise<any> {
    // console.log(':::service --::', param);
    // param ==> agentId
    return this.http.get('/api/channels/agent/' + param);
  }


  public getChannel(param: Dict<any>): Promise<any> {
    // param ==> id
    return this.http.get('/api/channels/' + param);
  }

  public delete(param: Dict<any>): Promise<any> {
    return this.http.delete(`/api/channels/${param.channelId}/${param.agentId}`);
  }

  public create(param: Dict<any>): Promise<any> {
    return this.http.post('/api/channels', param);
  }

  public edit(param: Dict<any>): Promise<any> {
    return this.http.put('/api/channels', param);
  }

  public channelTagAdd(param: Dict<any>): Promise<any> {
    return this.http.post('/api/channels/tagAdd', param);
  }

  public channelTagUpdate(param: Dict<any>): Promise<any> {
    return this.http.put('/api/channels/tagUpdate', param);
  }

  public getTagInfo(param: Dict<any>): Promise<any> {
    return this.http.get('/api/channels/tag/' + param);
  }

  public getSecret(): Promise<any> {
    return this.http.get('/api/channels/secret/');
  }

  public saveChannelMenu(param: Dict<any>): Promise<any> {
    return this.http.post('/api/menu', param);
  }

  public updateChannelMenu(param: Dict<any>): Promise<any> {
    return this.http.put('/api/menu', param);
  }

  public getChannelMenu(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/menu/${param.id}`);
  }

  public getChannelMenuList(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/menu/channel/${param.id}`);
  }

  public deleteChannelMenu(param: Dict<any>): Promise<any> {
    return this.http.delete(`/api/menu/${param.id}`);
  }

  public syncChannelMenu(param: Dict<any>): Promise<any> {
    return this.http.post(`/api/menu/sync/${param.id}`);
  }

  public savenNormalReply(param: Dict<any>): Promise<any> {
    return this.http.post('/api/reply', param);
  }

  public updateNormalReply(param: Dict<any>): Promise<any> {
    return this.http.put('/api/reply', param);
  }

  public getNormalReply(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/reply/${param.id}/${param.type}`);
  }

  public savenKeyWordReply(param: Dict<any>): Promise<any> {
    return this.http.post('/api/reply/keyword-reply-rule', param);
  }

  public updateKeyWordReply(param: Dict<any>): Promise<any> {
    return this.http.put('/api/reply/keyword-reply-rule', param);
  }

  public getKeyWordReply(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/reply/keyword-reply-rule/${param.id}`);
  }
  public getKeyWordReplyList(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/reply/keyword-reply-rules/page/${param.offset}/${param.limit}?channelId=${param.id}`);
  }

  public deleteKeyWordReply(param: Dict<any>): Promise<any> {
    return this.http.delete(`/api/reply/keyword-reply-rule/${param.id}`);
  }

}
